.help-dialog {
    position: fixed;
    color: #333;
    top: 100px;
    left: 0;
    right: 0;
    width: 300px;
    margin: auto;
    border-radius: 4px;
    padding: 20px;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
    z-index: 2;
  }
  
  .help-dialog h4 {
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .kbd-table td {
    padding: 4px;
}