/** Set the notes section to 100% when it is focused. */
.draft-board-item:focus {
    flex: 100%;
    opacity: 1;
}

/* Hide the other bits of player info when the notes column is focused. */
.draft-board-item-text-focused.draft-board-item:not(:focus-within) {
    width: 0px;
    padding-right: 0px;
    margin-left: 0px;
    overflow: hidden;
}

/* Define here so that the above rule can be more specific. */
.draft-board-item {
    margin-left: 16px;
    padding-right: 16px;
}
