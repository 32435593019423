body {
  /* Leave space for navbar & team nav. */
  padding-top: 76px; 
  background-color: #14151B;
  font-size: 14px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  background-color: transparent;
  color: #2681D4;
  text-decoration: none;
}

h1, h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h1, h2, h3, h4, h5 {
  font-weight: 600;
  line-height: 1.1;
}

em {
  font-weight: 600;
  font-style: normal;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.text-right {
  text-align: right;
}
