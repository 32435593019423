.multi-select {
    position: relative;
    color: #000;
}

.multi-select.disabled .text-input-area {
    background-color: #eee;
    color: #888;
    cursor: not-allowed;
}

.multi-select .text-input-area {
    min-height: 38px;
    display: block;
    width: 100%;
    padding: 2px 12px 2px 8px;
    font-size: 14px;
    line-height: 1.42857;
    cursor: text;

    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.multi-select .text-input-area.has-text-focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075) 0 0 8px #66afe9;
}

.multi-select input {
    vertical-align: top;
    display: inline-block;
    border: none;
    width: auto;
    height: 28px;
    padding-top: 4px;
    padding-left: 4px;
    padding-bottom: 4px;
}

.multi-select input:focus {
    outline: none;
}

.multi-select .selected-items {
    display: inline-block;
    margin-bottom: 0;
}

.multi-select .selected-item {
    display: inline-block;
    margin-top: 4px;
    margin-right: 6px;
    font-size: 1em;
    font-weight: 400;
    background-color: #eee;
    color: #333;
    user-select: none;
    -webkit-user-select: none;
    cursor: default;
    overflow: hidden;
    max-width: 100%;
    /* For close icon. */
    padding-right: 20px;
    position: relative;
    padding: 3px 20px 1px 7px;
    height: 19px;
}

.multi-select .selected-item.label {
   line-height: 1;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: 0.25em;
}

.multi-select .selected-item > span {
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
}

.multi-select .selected-item .fa-close {
    position: absolute;
    right: 4px;
    color: #888;
    top: 2px;
}

.multi-select .selected-item .fa-close:hover {
    color: #666;
}

.multi-select.single-item .selected-item {
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    text-align: left;
    line-height: 2.4;
    padding-left: 10px;
}

.multi-select.single-item .selected-item .fa-close {
    top: 0;
    right: 8px;
    width: 100%;
    right: 0;
    height: 100%;
    text-align: right;
    padding-right: 8px;
}
