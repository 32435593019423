.selectable-list {
    position: absolute;
    z-index: 10;
    width: 100%;
    min-width: 160px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border-radius: 4px;
    max-height: 300px;
    overflow: auto;
    display: none;
}

.selectable-list.open {
    display: block;
}

.selectable-list > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.selectable-list > ul > li {
    display: block;
    padding: 6px 12px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectable-list > ul > li.action-item {
    font-weight: 600;
    background-color: rgba(0,0,0,0.1);
}

.selectable-list > ul > li.action-item.active,
.selectable-list > ul > li.action-item:hover {
    background-color: rgba(0,0,0,0.15);

}

.selectable-list > ul > li.active,
.selectable-list > ul > li.active:hover {
    background: darken(#EAEBF7, 3%);
}

.selectable-list > ul > li:hover{
    background: #EAEBF7;
}

.selectable-list > ul > li:first-child{
    border-radius: 4px 4px 0 0;
}

.selectable-list > ul > li:last-child{
    border-radius: 0 0 4px 4px;
}

.selectable-list > ul > li .aux {
    color: #888;
    font-size: 10px;
    height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}